import Layout from '@/layout/homepage-layout/index.vue';

const routes = [
  {
    path: '/organization',
    name: 'Organization',
    redirect: '/organization/list',
    meta: {
      menu: {
        name: '养老机构列表',
      },
    },
    component: Layout,
    children: [
      {
        path: '/organization/list',
        name: 'OrganizationList',
        meta: {
          menu: {
            name: '养老机构列表',
          },
        },
        component: () => import('@/views/home/organization-list/index.vue'),
      },
      {
        path: '/organization/list/detail/:id',
        name: 'OrganizationDetail',
        props: true,
        meta: {
          menu: {
            name: '详情',
            hidden: true,
          },
          hidden: true,
          activeMenu: '/organization/list',
        },
        component: () => import('@/views/home/organization-list/detail/index.vue'),
      },
    ],
  },
];

export default routes;
