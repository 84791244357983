<template>
  <div class="app-wrapper">
    <Navbar />
    <div class="">
      <AppMain />
      <Footer v-if="!noFooter" />
    </div>
  </div>
</template>

<script>
// refer to Bootstrap's responsive design
import Footer from '@/components/footer/index.vue';
import Navbar from './navbar/index.vue';
import AppMain from './app-main.vue';

const { body } = document;
const WIDTH = 992;

export default {
  components: {
    Navbar,
    AppMain,
    Footer,
  },
  data() {
    return {
      currentMenu: [
        'Login',
      ],

      loading: false,
    };
  },
  computed: {
    noFooter() {
      const activeRouteName = this.$route.matched[0].name;
      return this.currentMenu.includes(activeRouteName);
    },
  },
  mounted() {
    const isMobile = this.$_isMobile();
    if (isMobile) {
      this.$store.dispatch('app/toggleDevice', 'mobile');
    }
  },
  methods: {
    // use $_ for mixins properties
    // https://vuejs.org/v2/style-guide/index.html#Private-property-names-essential
    $_isMobile() {
      const rect = body.getBoundingClientRect();
      return rect.width - 1 < WIDTH;
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-container {
  width: 100%;
  z-index: 99;
  position: fixed;
}
</style>
