import Layout from '@/layout/homepage-layout/index.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/homepage',
    meta: {
      menu: {
        name: '首页',
      },
    },
    component: Layout,
    children: [
      {
        path: '/homepage',
        name: 'Homepage',
        meta: {
          menu: {
            name: '首页',
          },
        },
        component: () => import('@/views/home/homepage/index.vue'),
      },
    ],
  },
];

export default routes;
