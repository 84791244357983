<template>
  <div class="navbar">
    <div
      class="navbar-logo"
      @click="toHomeClick"
    >
      <img
        class="logo"
        :src="logo"
        alt="logo"
      >
      <span class="title">通州区智能养老照护系统</span>
    </div>
    <div class="navbar-menu">
      <Sidebar />
    </div>
    <div class="navbar-search">
      <el-input
        v-model="search"
        placeholder="搜索养老机构"
        @keydown.enter.native="onSearchClick"
      >
        <template v-slot:suffix>
          <img
            class="search-icon"
            :src="searchIcon"
            alt="搜索"
            @click="onSearchClick"
          >
        </template>
      </el-input>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/homepage-logo.png';
import searchIcon from '@/assets/svg/search-icon.svg';
import Sidebar from '../sidebar/index.vue';

export default {
  components: {
    Sidebar,
  },
  data() {
    return {
      logo,
      searchIcon,
      search: '',
    };
  },
  methods: {
    toHomeClick() {
      this.$router.push({
        path: '/',
      });
    },

    onSearchClick() {
      if (this.search) {
        this.$router.push({
          name: 'OrganizationList',
          query: {
            name: this.search,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  min-height: 80px;
  min-width: 1200px;
  box-sizing: border-box;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #fff;
  position: relative;
  z-index: 99;
  .navbar-logo {
    min-width: 280px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .logo {
      width: 50px;
      height: 50px;
    }
    .title {
      font-size: 20px;
      font-weight: bold;
      margin-left: 10px;
    }
  }
  .search-icon {
    cursor: pointer;
  }
}
</style>

<style lang="scss">
.navbar-search {
  .el-input__inner {
    border-radius: 19px;
  }
  .el-input__suffix {
    display: flex;
    align-items: center;
  }
}
.navbar-menu {
  .el-menu.el-menu--horizontal {
    border: 0;
  }
}
</style>
