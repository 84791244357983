import Layout from '@/layout/elderly-layout/index.vue';
import policyConsultationActive from '@/assets/svg/elderly-menu-icon/policy-consultation-active.svg';
import policyConsultationInActive from '@/assets/svg/elderly-menu-icon/policy-consultation-inactive.svg';

const routes = [
  {
    path: '/policy-consultation',
    name: 'PolicyConsultation',
    redirect: '/policy-consultation/pension-policy',
    meta: {
      menu: {
        name: '政策咨询',
        icon: {
          active: policyConsultationActive,
          inactive: policyConsultationInActive,
        },
      },
    },
    component: Layout,
    children: [
      {
        path: '/policy-consultation/pension-policy',
        name: 'PensionPolicy',
        meta: {
          menu: {
            name: '养老政策',
          },
          breadcrumb: {
            label: '养老政策',
          },
        },
        component: () => import('@/views/elderly/policy-consultation/pension-policy/index.vue'),
      },
      {
        path: '/policy-consultation/pension-policy/detail/:id',
        name: 'PensionPolicyDetail',
        props: true,
        meta: {
          menu: {
            name: '政策详情',
            hidden: true,
          },
          breadcrumb: {
            parent: 'PensionPolicy',
            label: '政策详情',
          },
          activeMenu: '/policy-consultation/pension-policy',
        },
        component: () => import('@/views/elderly/policy-consultation/pension-policy/detail/index.vue'),
      },
      {
        path: '/policy-consultation/policy-advice',
        name: 'PolicyAdvice',
        meta: {
          menu: {
            name: '政策咨询',
          },
          breadcrumb: {
            label: '政策咨询',
          },
        },
        component: () => import('@/views/elderly/policy-consultation/policy-advice/index.vue'),
      },
      {
        path: '/policy-consultation/policy-advice/detail/:id',
        name: 'PolicyAdviceDetail',
        props: true,
        meta: {
          menu: {
            name: '咨询详情',
            hidden: true,
          },
          hidden: true,
          breadcrumb: {
            label: '咨询详情',
            parent: 'PolicyAdvice',
          },
          activeMenu: '/policy-consultation/policy-advice',
        },
        component: () => import('@/views/elderly/policy-consultation/policy-advice/detail/index.vue'),
      },
      {
        path: '/policy-consultation/my-consult',
        name: 'MyConsult',
        meta: {
          menu: {
            name: '我要咨询',
          },
          breadcrumb: {
            label: '我要咨询',
          },
        },
        component: () => import('@/views/elderly/policy-consultation/my-consult/index.vue'),
      },
      {
        path: '/policy-consultation/my-consult/detail/:id',
        name: 'MyConsultDetail',
        props: true,
        meta: {
          menu: {
            name: '咨询详情',
            hidden: true,
          },
          breadcrumb: {
            parent: 'MyConsult',
            label: '咨询详情',
          },
          activeMenu: '/policy-consultation/my-consult',
        },
        component: () => import('@/views/elderly/policy-consultation/policy-advice/detail/index.vue'),
      },
      {
        path: '/policy-consultation/my-consult/add',
        name: 'AddConsult',
        meta: {
          menu: {
            name: '新建咨询',
            hidden: true,
          },
          hidden: true,
          breadcrumb: {
            label: '新建咨询',
            parent: 'MyConsult',
          },
          activeMenu: '/policy-consultation/my-consult',
        },
        component: () => import('@/views/elderly/policy-consultation/my-consult/add/index.vue'),
      },
    ],
  },
];

export default routes;
