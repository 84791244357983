import Vue from 'vue';
import DialogPlugin from './dialog-plugin';

const dialogPlugin = {
  install(vue) {
    const $z = {
      $dialog: DialogPlugin(),
    };
    // eslint-disable-next-line no-param-reassign
    vue.prototype.$z = $z;
  },
};

Vue.use(dialogPlugin);
