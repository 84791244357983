export default {
  apiBaseUrl: '/portal/v1/api',
  // apiBaseUrl: 'http://10.66.1.128:9000/portal/v1/api',
  // apiBaseUrl: 'http://10.66.1.22:9000/portal/v1/api',
  bucketName: {
    portal: 'anhe-portal',
    inst: 'anhe-inst',
    supervise: 'anhe-care-supervise-public',
  },
  bucketUrl: {
    portal: 'https://anhe-portal.oss-cn-hangzhou.aliyuncs.com/',
    inst: 'https://anhe-inst.oss-cn-beijing.aliyuncs.com/',
    supervise: 'https://anhe-care-supervise-public.oss-cn-beijing.aliyuncs.com/',
  },
  nationalLevel: 'http://xxgk.mca.gov.cn:8011/gdnps/pc/index.jsp?mtype=1',
  municipalLevel: 'http://mzj.beijing.gov.cn/col/col6112/index.html',
};
