import Layout from '@/layout/elderly-layout/index.vue';
import initiateComplaintActiveIcon from '@/assets/svg/initiate-complaint-active-icon.svg';
import initiateComplaintInActiveIcon from '@/assets/svg/initiate-complaint-inactive-icon.svg';
import myComplaintActiveIcon from '@/assets/svg/my-complaint-active-icon.svg';
import myComplaintInActiveIcon from '@/assets/svg/my-complaint-inactive-icon.svg';

const routes = [
  {
    path: '/complaints-suggestions',
    name: 'ComplaintsSuggestions',
    redirect: '/complaints-suggestions/launch',
    component: Layout,
    meta: {
      menu: {
        name: '投诉建议',
      },
    },
    children: [
      {
        path: '/complaints-suggestions/launch',
        name: 'ComplaintsSuggestionsLaunch',
        meta: {
          menu: {
            name: '发起投诉',
          },
          breadcrumb: {
            label: '发起投诉',
          },
          icon: {
            active: initiateComplaintActiveIcon,
            inActive: initiateComplaintInActiveIcon,
          },
        },
        component: () => import('@/views/elderly/complaints-suggestions/initiate-complaint/index.vue'),
      },
      {
        path: '/complaints-suggestions/launch/complaint/:id',
        name: 'ComplaintDetail',
        props: true,
        meta: {
          menu: {
            name: '投诉内容',
          },
          hidden: true,
          breadcrumb: {
            label: '投诉内容',
            parent: 'ComplaintsSuggestionsLaunch',
          },
          activeMenu: '/complaints-suggestions/launch',
        },
        component: () => import('@/views/elderly/complaints-suggestions/initiate-complaint/detail/index.vue'),
      },
      {
        path: '/complaints-suggestions/launch/detail/:id',
        name: 'ComplaintLaunchDetail',
        props: true,
        meta: {
          menu: {
            name: '订单详情',
            hidden: true,
          },
          hidden: true,
          breadcrumb: {
            label: '订单详情',
            parent: 'ComplaintsSuggestionsLaunch',
          },
          activeMenu: '/complaints-suggestions/launch',
        },
        component: () => import('@/views/elderly/service-appointment/my-reservation/detail/index.vue'),
      },
      {
        path: '/complaints-suggestions/mine',
        name: 'ComplaintsSuggestionsMine',
        meta: {
          menu: {
            name: '我的投诉',
          },
          breadcrumb: {
            label: '我的投诉',
          },
          icon: {
            active: myComplaintActiveIcon,
            inActive: myComplaintInActiveIcon,
          },
        },
        component: () => import('@/views/elderly/complaints-suggestions/my-complaint/index.vue'),
      },
      {
        path: '/complaints-suggestions/mine/feedback/:id',
        name: 'ComplaintsFeedback',
        props: true,
        meta: {
          menu: {
            name: '投诉反馈',
          },
          hidden: true,
          breadcrumb: {
            label: '投诉反馈',
            parent: 'ComplaintsSuggestionsMine',
          },
          activeMenu: '/complaints-suggestions/mine',
        },
        component: () => import('@/views/elderly/complaints-suggestions/my-complaint/feedback/index.vue'),
      },
      {
        path: '/complaints-suggestions/mine/detail/:id',
        name: 'ComplaintMineDetail',
        props: true,
        meta: {
          menu: {
            name: '订单详情',
            hidden: true,
          },
          hidden: true,
          breadcrumb: {
            label: '订单详情',
            parent: 'ComplaintsSuggestionsMine',
          },
          activeMenu: '/complaints-suggestions/mine',
        },
        component: () => import('@/views/elderly/service-appointment/my-reservation/detail/index.vue'),
      },
    ],
  },
];

export default routes;
