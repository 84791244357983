<template>
  <div class="elderly-layout-header">
    <div
      class="header-title"
      @click="onHomeClick"
    >
      养老服务门户-养老服务预约与健康管理系统
    </div>
    <div class="header-menu">
      <router-link
        class="header-menu-item"
        v-for="item in defaultRoutes"
        :key="item.name"
        :to="item.path"
      >{{item.meta.menu.name}}</router-link>
      <el-button
        style="padding: 0; color: #fff"
        type="text"
        @click="handlerLogout"
      >注销</el-button>
    </div>
  </div>
</template>

<script>
import { elderlyTopRoutes } from '@/router/menuRoutes';
import { mapActions } from 'vuex';

export default {
  computed: {
    defaultRoutes() {
      return elderlyTopRoutes;
    },
  },
  methods: {
    ...mapActions({
      logout: 'LOGOUT',
    }),

    handlerLogout() {
      this.$confirm('确认注销', '确认', {
        type: 'warning',
      })
        .then(() => {
          this.logout();
        })
        .catch(() => { });
    },

    onHomeClick() {
      this.$router.push({
        path: '/',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.elderly-layout-header {
  width: 100%;
  height: 50px;
  padding: 12px 30px;
  box-sizing: border-box;
  background: #fa4f74;
  position: fixed;
  top: 0;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header-title {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
  }
  .header-menu {
    font-size: 14px;
    color: #fff;
    line-height: 22px;
    .header-menu-item {
      padding: 0 10px;
    }
    .header-menu-item:first-child {
      padding-right: 10px;
      border-right: 1px solid #fff;
    }
  }
}
</style>
