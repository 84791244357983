const getters = {
  sidebar: (state) => state.app.sidebar,
  device: (state) => state.app.device,
  token: (state) => state.user.token,
  userName: (state) => state.user.userName,
  account: (state) => state.user.account,
  realName: (state) => state.user.realName,
  needPerfection: (state) => state.user.needPerfection,
  wechatNickName: (state) => state.user.wechatNickName,
  hasResume: (state) => state.user.hasResume,
  hasResumeFile: (state) => state.user.hasResumeFile,
  currentStep: (state) => state.appointment.currentStep,
  currentElderId: (state) => state.appointment.currentElderId,
  currentElderName: (state) => state.appointment.currentElderName,
  currentOrganizationId: (state) => state.appointment.currentOrganizationId,
  needChangePwd: (state) => state.user.needChangePwd,
};
export default getters;
