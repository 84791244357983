<template>
  <div class="second-siderbar">
    <router-link
      class="second-siderbar-item"
      v-for="(item, index) in tabList"
      :key="index"
      :to="{ name: item.name}"
    >
      <img
        class="item-icon"
        :src="currentRoute === item.name ? item.meta.icon.active : item.meta.icon.inActive"
        alt=""
      >
      <span
        class="item-title"
        :class="currentRoute === item.name ? 'curret-tabs' : ''"
      >
        {{ item.meta.menu.name }}
      </span>
    </router-link>
  </div>
</template>

<script>

export default {
  props: {
    tabList: {
      type: Array,
      require: true,
    },
  },

  computed: {
    currentRoute() {
      return this.$route.name;
    },
  },

};
</script>

<style lang="scss" scoped>
.second-siderbar {
  min-width: 768px;
  width: 768px;
  margin: 20px auto 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  .second-siderbar-item {
    display: flex;
    align-items: center;
    .item-icon {
      margin-right: 13px;
    }
    .item-title {
      font-size: 14px;
      line-height: 22px;
      color: #666666;
    }
  }
  .curret-tabs {
    color: #fa4f74 !important;
    position: relative;
    &::after {
      content: '';
      display: inline-block;
      width: 57px;
      height: 4px;
      border-radius: 2px;
      position: absolute;
      left: 0;
      bottom: -6px;
      background: #fa4f74;
    }
  }
}
</style>
