import defaultSettings from '@/settings';

const { showSettings, fixedHeader, sidebarLogo } = defaultSettings;

const state = {
  showSettings,
  fixedHeader,
  sidebarLogo,
};

export default state;
