import Layout from '@/layout/homepage-layout/index.vue';

const routes = [
  {
    path: '/message',
    name: 'Message',
    meta: {
      menu: {
        name: '服务信息推荐',
      },
    },
    component: Layout,
    children: [
      {
        path: '/message/list',
        name: 'MessageList',
        meta: {
          menu: {
            name: '服务信息推荐',
          },
        },
        component: () => import('@/views/home/message-list/index.vue'),
      },
      {
        path: '/message/detail/:id',
        name: 'MessageDetail',
        props: true,
        meta: {
          menu: {
            name: '信息详情',
            hidden: true,
          },
          hidden: true,
          activeMenu: '/message/list',
        },
        component: () => import('@/views/home/message-list/detail/index.vue'),
      },
    ],
  },
];

export default routes;
