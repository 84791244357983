import Layout from '@/layout/homepage-layout/index.vue';

const routes = [
  {
    path: '/hompage-pension-knowledge',
    name: 'HomepagePensionKnowledge',
    redirect: '/hompage-pension-knowledge/homepage-health-consultation',
    meta: {
      menu: {
        name: '养老知识',
      },
    },
    component: Layout,
    children: [
      {
        path: '/hompage-pension-knowledge/homepage-health-consultation',
        name: 'HomepageHealthConsultation',
        meta: {
          menu: {
            name: '健康资讯',
          },
        },
        component: () => import('@/views/home/pension-knowledge/health-consultation/index.vue'),
      },
      {
        path: '/hompage-pension-knowledge/homepage-health-consultation/detail/:id',
        name: 'HomepageHealthConsultationDetail',
        props: true,
        meta: {
          menu: {
            name: '详情',
            hidden: true,
          },
          activeMenu: '/hompage-pension-knowledge/homepage-health-consultation',
        },
        component: () => import('@/views/home/pension-knowledge/health-consultation/detail/index.vue'),
      },
      {
        path: '/hompage-pension-knowledge/homepage-chronic-disease-reference',
        name: 'HomepageChronicDiseaseReference',
        meta: {
          menu: {
            name: '慢性病小常识',
          },
        },
        component: () => import('@/views/home/pension-knowledge/chronic-disease-reference/index.vue'),
      },
      {
        path: '/hompage-pension-knowledge/homepage-chronic-disease-reference/detail/:id',
        name: 'HomepageChronicDiseaseReferenceDetail',
        props: true,
        meta: {
          menu: {
            name: '详情',
            hidden: true,
          },
          activeMenu: '/hompage-pension-knowledge/homepage-chronic-disease-reference',
        },
        component: () => import('@/views/home/pension-knowledge/chronic-disease-reference/detail/index.vue'),
      },
    ],
  },
];

export default routes;
