<template>
  <section class="app-main">
    <transition
      name="fade-transform"
      mode="out-in"
    >
      <SecondSiderbar
        v-if="hasSecondSiderbar"
        :tabList="currentTabList"
      />
    </transition>
    <transition
      name="fade-transform"
      mode="out-in"
    >
      <router-view :key="key" />
    </transition>
    <div class="app-footer">Copyright © 中共北京市通州区委社会工委区民政局 2021-2023</div>
  </section>
</template>

<script>
import { elderlyTopRoutes } from '@/router/menuRoutes';
import SecondSiderbar from './second-siderbar/index.vue';

export default {
  name: 'AppMain',
  components: {
    SecondSiderbar,
  },
  data() {
    return {
      pathList: [
        'ComplaintsSuggestionsLaunch',
        'ComplaintsSuggestionsMine',
        'ElderlyPersonalCenterInfo',
        'ElderlyPersonalCenterAppointment',
        'ElderlyPersonalCenterComplaint',
      ],
    };
  },
  computed: {
    key() {
      return this.$route.path;
    },
    currentRoute() {
      return this.$route.name;
    },
    hasSecondSiderbar() {
      return this.pathList.includes(this.currentRoute);
    },

    currentTabList() {
      const current = this.$route.matched[0].path;
      return elderlyTopRoutes
        .filter((item) => item.path === current)[0]
        .children.filter((item) => !item.meta.hidden);
    },
  },
};
</script>

<style scoped>
.app-main {
  min-height: calc(100vh - 100px);
  width: 100%;
  min-width: 768px;
  position: relative;
  overflow: hidden;
  background: #f0f2f5;
}
.fixed-header + .app-main {
  padding-top: 50px;
}
.app-footer {
  font-size: 12px;
  margin-bottom: 30px;
  text-align: center;
  line-height: 20px;
  color: #666666;
}
</style>
