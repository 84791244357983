import { getToken } from '@/utils/local-store-token';

const state = {
  token: getToken(),
  account: '',
  wechatNickName: '',
  needPerfection: true,
  realName: false,
  userName: '',
  hasResume: '',
  hasResumeFile: '',
  isRemember: false,
  needChangePwd: false,
};

export default state;
