import Homepage from './modules/homepage/homepage';
import Organization from './modules/homepage/organization-list';
import PensionKonwledge from './modules/homepage/pension-knowledge';
import PolicyConsultation from './modules/homepage/policy-consultation';
import MessageList from './modules/homepage/message-list';
import Login from './modules/homepage/login';
import ScientificCareProgram from './modules/homepage/scientific-care-program';

const routes = [
  ...Homepage,
  ...Organization,
  ...PensionKonwledge,
  ...PolicyConsultation,
  ...MessageList,
  ...Login,
  ...ScientificCareProgram,
];

export default routes;
