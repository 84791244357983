import Vue from 'vue';
import VueCountdown from '@chenfengyuan/vue-countdown';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import App from './App.vue';
import router from './router';
import store from './store';

import '@/plugins/element-ui';
import '@/plugins/dialog';
import '@/plugins/vuelidate-errors';
import '@/plugins/vuelidate';
import '@/plugins/router-permission';

import '@/styles/index.scss';

import '@/utils/request';
import '@/utils/request-interceptors';

Vue.component(VueCountdown.name, VueCountdown);

Vue.use(VueDOMPurifyHTML);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
