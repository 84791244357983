const sidebarStyle = {
  menuText: '#666666',
  menuActiveText: '#fa4f74',
  subMenuActiveText: '#fa4f74',
  menuBg: '#fff',
  menuHover: '#fcf3f5',

  subMenuBg: '#fff',
  subMenuHover: '#fcf3f5',

  sideBarWidth: '180px',
};

export default sidebarStyle;
