import ServiceAppointment from './modules/elderly/service-appointment';
import RelatedElderly from './modules/elderly/related-elderly';
import NursingFacility from './modules/elderly/nursing-facility';
import PolicyConsultation from './modules/elderly/policy-consultation';
import PensionKnowledge from './modules/elderly/pension-knowledge';
import HealthManagement from './modules/elderly/health-management';
import ComplaintsSuggestions from './modules/elderly/complaints-suggestions';
import PersonalCenter from './modules/elderly/personal-center';

const elderlyLeftRoutes = [
  ...ServiceAppointment,
  ...RelatedElderly,
  ...NursingFacility,
  ...PolicyConsultation,
  ...PensionKnowledge,
  ...HealthManagement,
];

const elderlyTopRoutes = [
  ...ComplaintsSuggestions,
  ...PersonalCenter,
];

export { elderlyLeftRoutes, elderlyTopRoutes };
