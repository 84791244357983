export default {
  apiBaseUrl: '/portal/v1/api',
  bucketName: {
    portal: 'anhe-portal',
    inst: 'anhe-inst',
    supervise: 'anhe-supervise',
  },
  bucketUrl: {
    portal: 'https://znzh-oss.zwyun.anhetech.net/yanglao/',
    inst: 'https://znzh-oss.zwyun.anhetech.net/yanglao/',
    supervise: 'https://znzh-oss.zwyun.anhetech.net/yanglao/',
  },
  nationalLevel: 'http://xxgk.mca.gov.cn:8011/gdnps/pc/index.jsp?mtype=1',
  municipalLevel: 'http://mzj.beijing.gov.cn/col/col6112/index.html',
};
