import Layout from '@/layout/homepage-layout/index.vue';

const routes = [
  {
    path: '/hompage-policy-consultation',
    name: 'HompagePolicyConsultation',
    redirect: '/hompage-policy-consultation/hompage-pension-policy',
    meta: {
      menu: {
        name: '政策咨询',
      },
    },
    component: Layout,
    children: [
      {
        path: '/hompage-policy-consultation/hompage-pension-policy',
        name: 'HompagePensionPolicy',
        meta: {
          menu: {
            name: '养老政策',
          },
        },
        component: () => import('@/views/home/policy-consultation/pension-policy/index.vue'),
      },
      {
        path: '/hompage-policy-consultation/hompage-pension-policy/detail/:id',
        name: 'HompagePensionPolicyDetail',
        props: true,
        meta: {
          menu: {
            name: '详情',
            hidden: true,
          },
          activeMenu: '/hompage-policy-consultation/hompage-pension-policy',
        },
        component: () => import('@/views/home/policy-consultation/pension-policy/detail/index.vue'),
      },
      {
        path: '/hompage-policy-consultation/hompage-question-consultation',
        name: 'HompageQuestionConsultation',
        meta: {
          menu: {
            name: '问题咨询',
          },
        },
        component: () => import('@/views/home/policy-consultation/question-consultation/index.vue'),
      },
      {
        path: '/hompage-policy-consultation/hompage-question-consultation/detail/:id',
        name: 'HompageQuestionConsultationDetail',
        props: true,
        meta: {
          menu: {
            name: '详情',
            hidden: true,
          },
          activeMenu: '/hompage-policy-consultation/hompage-question-consultation',
        },
        component: () => import('@/views/home/policy-consultation/question-consultation/detail/index.vue'),
      },
    ],
  },
];

export default routes;
