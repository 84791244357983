import Vue from 'vue';
import VueRouter from 'vue-router';
import { elderlyLeftRoutes, elderlyTopRoutes } from './menuRoutes';
import HomepageRoutes from './homepageRoutes';
import TalentMenuRoutes from './talent-menuRoutes';

Vue.use(VueRouter);

const routes = [
  ...HomepageRoutes,
  ...elderlyLeftRoutes,
  ...elderlyTopRoutes,
  ...TalentMenuRoutes,
  {
    path: '/need-change-password',
    component: () => import('@/views/change-password/index.vue'),
  },
];

const router = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
