import Layout from '@/layout/talent-layout/index.vue';

const routes = [
  {
    path: '/training-web/#/',
    name: 'TrainingSystem',
    meta: {
      menu: {
        name: '培训系统',
      },
      isExternalLink: true,
    },
    component: Layout,
    children: [],
  },
];

export default routes;
