import { setToken } from '@/utils/local-store-token';

export function SET_TOEKN(state, token) {
  state.token = token;
  setToken(token);
}

export function SET_ACCOUNT(state, account) {
  state.account = account;
}

export function SET_USERNAME(state, userName) {
  state.userName = userName;
}

export function SET_WECHAT_NICK_NAME(state, wechatNickName) {
  state.namwechatNickNamee = wechatNickName;
}

export function SET_NEED_PERFECTION(state, needPerfection) {
  state.needPerfection = needPerfection;
}

export function SET_REAL_NAME(state, realName) {
  state.realName = realName;
}

export function SET_HAS_RESUME(state, hasResume) {
  state.hasResume = hasResume;
}

export function SET_HAS_RESUME_FILE(state, hasResumeFile) {
  state.hasResumeFile = hasResumeFile;
}

export function SET_IS_REMEMBER(state, isRemember) {
  state.isRemember = isRemember;
}

export function SET_NEED_CHANGE_PWD(state, needChangePwd) {
  state.needChangePwd = needChangePwd;
}
