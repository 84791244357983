<template>
  <div class="talent-navbar">
    <div class="navbar-logo">
      <img
        :src="logo"
        alt=""
        class="logo"
      >
      <div
        class="text"
        @click="onHomeClick"
      >通州区智能养老照护系统</div>
    </div>
    <div class="navbar-menu">
      <Siderbar />
    </div>
    <div class="navbar-person">
      <span
        class="person"
        @click="handlerPersonal"
      >个人中心</span>
      <span style="margin: 0 2px">|</span>
      <span>你好，{{ userName }}</span>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/homepage-logo.png';
import { mapGetters } from 'vuex';
import Siderbar from '../sidebar/index.vue';

export default {
  components: {
    Siderbar,
  },
  data() {
    return {
      logo,
    };
  },
  computed: {
    ...mapGetters(['userName']),
  },
  methods: {
    handlerPersonal() {
      if (this.$route.name === 'TalentPersonalCenter') {
        return;
      }
      this.$router.push({
        name: 'TalentPersonalCenter',
      });
    },

    onHomeClick() {
      this.$router.push({
        path: '/',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.talent-navbar {
  height: 80px;
  min-width: 1200px;
  padding: 15px 80px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .navbar-logo {
    cursor: pointer;
    display: flex;
    align-items: center;
    .logo {
      margin-right: 10px;
    }
    .text {
      font-size: 20px;
      line-height: 16px;
      font-weight: bold;
      color: #333333;
    }
  }
  .navbar-person {
    font-size: 14px;
    line-height: 20px;
    color: #999999;
    .person {
      cursor: pointer;
      &:hover {
        color: #fa4f74;
      }
    }
  }
}
</style>
