import Layout from '@/layout/elderly-layout/index.vue';
import relatedElderlyActive from '@/assets/svg/elderly-menu-icon/related-elderly-active.svg';
import relatedElderlyInActive from '@/assets/svg/elderly-menu-icon/related-elderly-inactive.svg';

const routes = [
  {
    path: '/related-elderly',
    name: 'RelatedElderly',
    redirect: '/related-elderly/list',
    component: Layout,
    meta: {
      menu: {
        name: '关联用户',
        icon: {
          active: relatedElderlyActive,
          inactive: relatedElderlyInActive,
        },
      },
    },
    children: [
      {
        path: '/related-elderly/list',
        name: 'RelatedElderlyList',
        meta: {
          menu: {
            name: '关联用户',
          },
          breadcrumb: {
            label: '关联用户',
          },
          activeMenu: '/related-elderly/list',
        },
        component: () => import('@/views/elderly/related-elderly/index.vue'),
      },
      {
        path: '/related-elderly/detail/:id',
        name: 'RelatedElderlyDetail',
        props: true,
        meta: {
          menu: {
            name: '老人详情',
          },
          hidden: true,
          breadcrumb: {
            label: '老人详情',
            parent: 'RelatedElderlyList',
          },
          activeMenu: '/related-elderly/list',
        },
        component: () => import('@/views/elderly/related-elderly/detail/index.vue'),
      },
    ],
  },
];

export default routes;
