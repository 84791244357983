import Layout from '@/layout/homepage-layout/index.vue';

const routes = [
  {
    path: '/scientific-care-program',
    name: 'ScientificCareProgram',
    redirect: '/scientific-care-program/index',
    meta: {
      menu: {
        name: '科学照护方案',
        hidden: true,
      },
    },
    component: Layout,
    children: [
      {
        path: '/scientific-care-program/index',
        name: 'ScientificCareProgramIndex',
        meta: {
          menu: {
            name: '科学照护方案',
            hidden: true,
          },
        },
        component: () => import('@/views/home/scientific-care-program/index.vue'),
      },
    ],
  },
];

export default routes;
