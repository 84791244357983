export function SET_CURRENT_STEP(state, currentStep) {
  state.currentStep = currentStep;
}

export function SET_CURRENT_ELDER_ID(state, currentElderId) {
  state.currentElderId = currentElderId;
}

export function SET_CURRENT_ELDER_NAME(state, currentElderName) {
  state.currentElderName = currentElderName;
}

export function SET_CURRENT_ORGANIZATION_ID(state, currentOrganizationId) {
  state.currentOrganizationId = currentOrganizationId;
}
