import Layout from '@/layout/elderly-layout/index.vue';
import personInfoActiveIcon from '@/assets/svg/person-info-active-icon.svg';
import personInfoInActiveIcon from '@/assets/svg/person-info-inactive-icon.svg';
import serviceAppointmentActive from '@/assets/svg/elderly-menu-icon/service-appointment-active.svg';
import serviceAppointmentInActive from '@/assets/svg/elderly-menu-icon/service-appointment-inactive.svg';
import myComplaintActiveIcon from '@/assets/svg/my-complaint-active-icon.svg';
import myComplaintInActiveIcon from '@/assets/svg/my-complaint-inactive-icon.svg';

const routes = [
  {
    path: '/elderly-personal-center',
    name: 'ElderlyPersonalCenter',
    redirect: '/elderly-personal-center/info',
    meta: {
      menu: {
        name: '个人中心',
      },
    },
    component: Layout,
    children: [
      {
        path: '/elderly-personal-center/info',
        name: 'ElderlyPersonalCenterInfo',
        meta: {
          menu: {
            name: '个人信息',
          },
          breadcrumb: {
            label: '个人信息',
          },
          icon: {
            active: personInfoActiveIcon,
            inActive: personInfoInActiveIcon,
          },
        },
        component: () => import('@/views/elderly/elderly-personal-center/personal-info/index.vue'),
      },
      {
        path: '/elderly-personal-center/my-appointment',
        name: 'ElderlyPersonalCenterAppointment',
        meta: {
          menu: {
            name: '我的预约',
          },
          breadcrumb: {
            label: '我的预约',
          },
          icon: {
            active: serviceAppointmentActive,
            inActive: serviceAppointmentInActive,
          },
        },
        component: () => import('@/views/elderly/service-appointment/my-reservation/index.vue'),
      },
      {
        path: '/elderly-personal-center/my-complaint',
        name: 'ElderlyPersonalCenterComplaint',
        meta: {
          menu: {
            name: '我的投诉',
          },
          breadcrumb: {
            label: '我的投诉',
          },
          icon: {
            active: myComplaintActiveIcon,
            inActive: myComplaintInActiveIcon,
          },
        },
        component: () => import('@/views/elderly/complaints-suggestions/my-complaint/index.vue'),
      },
    ],
  },
];

export default routes;
