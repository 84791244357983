<template>
  <section class="app-main">
    <transition
      name="fade-transform"
      mode="out-in"
    >
      <PersonalTabs v-if="hasPersonalTabs" />
    </transition>
    <transition
      name="fade-transform"
      mode="out-in"
    >
      <router-view :key="key" />
    </transition>
  </section>
</template>

<script>
import PersonalTabs from './personal-tabs/index.vue';

export default {
  name: 'AppMain',
  components: {
    PersonalTabs,
  },
  data() {
    return {
      currentList: [
        'TalentPersonalCenter',
        'MyResume',
        'ApplicationRecord',
        'SubsidyApplicationRecord',
        'SubsidyApplicationRecordDetail',
      ],
    };
  },
  computed: {
    key() {
      return this.$route.path;
    },
    hasPersonalTabs() {
      const currentRoute = this.$route;
      return this.currentList.includes(currentRoute.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-main {
  /*50 = navbar  */
  overflow-x: hidden;
  overflow-y: hidden;
  min-width: 1200px;
  min-height: calc(100vh - 300px);
  background: #faf9f8;
  position: relative;
  z-index: 0;
  &::before {
    content: '';
    display: block;
    width: 1422px;
    height: 1495px;
    position: absolute;
    top: 727px;
    left: -135px;
    z-index: -1;
    background: url('~@/assets/homepage-background.png');
  }
  &::after {
    content: '';
    display: block;
    width: 245px;
    height: 311px;
    position: absolute;
    top: 900px;
    right: 0;
    z-index: -1;
    background: url('~@/assets/organize-background.png');
  }
}
.fixed-header + .app-main {
  padding-top: 50px;
}
</style>
