import { request } from '@/utils/request';

// 登录
export function Login(parameters) {
  return request({
    url: '/user/login-2fa',
    method: 'POST',
    data: parameters,
  });
}

// 获取sm2公钥
export function getKeyPair() {
  return request({
    url: '/user/key-pair',
    method: 'POST',
  });
}

// 登录
export function LoginBySafe(data) {
  return request({
    url: '/user/login-safe',
    method: 'POST',
    data,
  });
}

// export function Login(parameters) {
//   return request({
//     url: '/user/login',
//     method: 'POST',
//     data: parameters,
//   });
// }

// 获取用户信息
export function getUserInfo() {
  return request({
    url: '/user/info',
    method: 'GET',
  });
}

// 注销登录
export function Logout() {
  return request({
    url: '/user/logout',
    method: 'DELETE',
  });
}

// 注册用户
export function register(parameters) {
  return request({
    url: '/user/register',
    method: 'PUT',
    data: parameters,
  });
}

// 获取验证码
export function getCode(parameters) {
  return request({
    url: '/sms/vercode',
    method: 'POST',
    data: parameters,
  });
}

// 重置密码
export function resetPassword(parameters) {
  return request({
    url: '/user/password/reset',
    method: 'POST',
    data: parameters,
  });
}

// 修改密码
export function changePassword(parameters) {
  return request({
    url: '/user/password/change',
    method: 'POST',
    data: parameters,
  });
}

// 实名认证
export function handlerRealName(parameters) {
  return request({
    url: '/user/real-name',
    method: 'POST',
    data: parameters,
  });
}

// 上传实名认证文件
export function uploadRealNameFile(parameters) {
  return request({
    url: '/resource/upload/real-name',
    params: parameters.type,
    data: parameters.data,
  });
}

// 获取sts授权信息
export function getAuthorizationInfo(parameters) {
  return request({
    url: '/resource/oss/sts',
    method: 'GET',
    params: parameters,
  });
}

// 实名认证后完善个人信息
export function perfectPersonalInfo(parameters) {
  return request({
    url: '/user/real-name/info',
    method: 'POST',
    data: parameters,
  });
}

// 获取社区列表
export function getCommunityList(parameters) {
  return request({
    url: '/community/stair/list',
    method: 'GET',
    params: parameters,
  });
}

// 获取街道列表
export function getStreetList() {
  return request({
    url: '/community/street/list',
    method: 'GET',
  });
}

// 上传文件
export function handleUploadFile(parameters) {
  return request({
    url: '/common/file-upload/',
    method: 'PUT',
    data: parameters.data,
  });
}
