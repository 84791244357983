import Layout from '@/layout/elderly-layout/index.vue';
import serviceAppointmentActive from '@/assets/svg/elderly-menu-icon/service-appointment-active.svg';
import serviceAppointmentInActive from '@/assets/svg/elderly-menu-icon/service-appointment-inactive.svg';

const routes = [
  {
    path: '/service-appointment',
    name: 'ServiceAppointment',
    redirect: '/service-appointment/home-service',
    component: Layout,
    meta: {
      menu: {
        name: '服务预约',
        icon: {
          active: serviceAppointmentActive,
          inactive: serviceAppointmentInActive,
        },
      },
    },
    children: [
      {
        path: '/service-appointment/home-service/',
        name: 'HomeService',
        meta: {
          menu: {
            name: '上门服务',
          },
          breadcrumb: {
            label: '上门服务',
          },
        },
        component: () => import('@/views/elderly/service-appointment/home-service/index.vue'),
      },
      {
        path: '/service-appointment/home-service/order/detail/:id',
        name: 'HomeServiceOrderDetail',
        props: true,
        meta: {
          menu: {
            name: '所有预约',
            hidden: true,
          },
          breadcrumb: {
            label: '所有预约',
            parent: 'HomeService',
          },
          activeMenu: '/service-appointment/home-service',
        },
        component: () => import('@/views/elderly/service-appointment/my-reservation/detail/index.vue'),
      },
      {
        path: '/service-appointment/home-service/organization/detail/:id',
        name: 'HomeServiceOrganizationDetail',
        props: true,
        meta: {
          menu: {
            name: '机构详情',
            hidden: true,
          },
          breadcrumb: {
            label: '机构详情',
            parent: 'HomeService',
          },
          activeMenu: '/service-appointment/home-service',
        },
        component: () => import('@/views/elderly/nursing-facility/detail/index.vue'),
      },
      {
        path: '/service-appointment/home-service/detail/:id',
        name: 'HomeServiceDetail',
        props: true,
        meta: {
          menu: {
            name: '所有预约',
            hidden: true,
          },
          hidden: true,
          breadcrumb: {
            label: '所有预约',
            parent: 'HomeService',
          },
          activeMenu: '/service-appointment/home-service',
        },
        component: () => import('@/views/elderly/service-appointment/home-service/detail/index.vue'),
      },
      {
        path: '/service-appointment/my-reservation',
        name: 'MyReservation',
        meta: {
          menu: {
            name: '我的预定',
          },
          breadcrumb: {
            label: '我的预定',
          },
        },
        component: () => import('@/views/elderly/service-appointment/my-reservation/index.vue'),
      },
      {
        path: '/service-appointment/my-reservation/detail/:id',
        name: 'MyReservationDetail',
        props: true,
        meta: {
          menu: {
            name: '订单详情',
            hidden: true,
          },
          hidden: true,
          breadcrumb: {
            label: '订单详情',
            parent: 'MyReservation',
          },
          activeMenu: '/service-appointment/my-reservation',
        },
        component: () => import('@/views/elderly/service-appointment/my-reservation/detail/index.vue'),
      },
    ],
  },
];

export default routes;
