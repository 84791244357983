<template>
  <section class="app-main homepage-main">
    <transition
      name="fade-transform"
      mode="out-in"
    >
      <router-view :key="key" />
    </transition>
  </section>
</template>

<script>

export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style scoped>
.app-main {
  min-width: 1200px;
  overflow: hidden;
  /* min-height: calc(100vh - 380px); */
  background: #faf9f8;
}
.fixed-header + .app-main {
  padding-top: 50px;
}
</style>

<style lang="scss">
.homepage-main {
  .app-container {
    min-height: calc(100vh - 420px) !important;
  }
}
</style>
