/* eslint-disable */
import Vue from 'vue';

export function merge(target, source) {
  for (const key in source) {
    if (key !== 'spinner' && Object(source[key]) === source[key]) {
      target[key] = Object(target[key]) !== target[key]
        ? {}
        : { ...target[key] }

      merge(target[key], source[key])
    }
    else {
      target[key] = source[key]
    }
  }
}

export default function (DefaultComponent) {
  return ({
    className, class: klass, style, component, root, parent, persistent, ...props
  }) => {
    klass !== void 0 && (props.cardClass = klass);
    style !== void 0 && (props.cardStyle = style);

    const isCustom = component !== void 0;
    let DialogComponent; let
      attrs;

    if (isCustom === true) {
      DialogComponent = component;
    } else {
      DialogComponent = DefaultComponent;
      attrs = props;
    }

    const okFns = [];
    const cancelFns = [];
    const API = {
      onOk(fn) {
        okFns.push(fn);
        return API;
      },
      onCancel(fn) {
        cancelFns.push(fn);
        return API;
      },
      onDismiss(fn) {
        okFns.push(fn);
        cancelFns.push(fn);
        return API;
      },
      hide() {
        vm.$refs.dialog.hide();
        return API;
      },
      update({
        className, class: klass, style, component, root, parent, ...cfg
      }) {
        if (vm !== null) {
          klass !== void 0 && (cfg.cardClass = klass);
          style !== void 0 && (cfg.cardStyle = style);

          if (isCustom === true) {
            Object.assign(props, cfg);
          } else {
            merge(props, cfg);

            // need to change "attrs" reference to
            // actually reflect it in underlying component
            // when we force update it
            attrs = { ...props };
          }

          vm.$forceUpdate();
        }

        return API;
      },
    };

    const node = document.createElement('div');
    document.body.appendChild(node);

    let emittedOK = false;

    const on = {
      ok: (data) => {
        emittedOK = true;
        okFns.forEach((fn) => { fn(data); });
      },

      hide: () => {
        vm.$destroy();
        vm.$el.remove();
        vm = null;

        if (emittedOK !== true) {
          cancelFns.forEach((fn) => { fn(); });
        }
      },
    };

    let vm = new Vue({
      name: 'ZGlobalDialog',

      el: node,
      parent: parent === void 0 ? root : parent,

      watch: {
        $route() {
          if (!persistent) {
            this.$refs.dialog.hide();
          }
        }
      },

      render(h) {
        return h(DialogComponent, {
          ref: 'dialog',
          props,
          attrs,
          on,
        });
      },

      mounted() {
        this.$refs.dialog.show();
      },
    });

    return API;
  };
}
