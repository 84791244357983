import router from '@/router';
import store from '@/store';

const whiteList = [
  'Homepage',
  'LoginMain',
  'LoginSafeMain',
  'ForgetPassword',
  'Register',
  'OrganizationList',
  'OrganizationDetail',
  'HomepageHealthConsultation',
  'HomepageHealthConsultationDetail',
  'HomepageChronicDiseaseReference',
  'HomepageChronicDiseaseReferenceDetail',
  'HompagePensionPolicy',
  'HompagePensionPolicyDetail',
  'HompageQuestionConsultation',
  'HompageQuestionConsultationDetail',
  'MessageList',
  'MessageDetail',
  'ScientificCareProgramIndex',
];

const blackList = [
  '/login/register',
  '/login/main/elderly',
  '/login/main/talent',
  '/login-safe/main/elderly',
  '/login-safe/main/talent',
];

router.beforeEach(async (to, from, next) => {
  const { token } = store.getters;
  if (token) {
    if (blackList.includes(to.path)) {
      next({ path: '/' });
      return;
    }

    if (!store.getters.account) {
      try {
        await store.dispatch('GET_INFO');

        next({ ...to, replace: true });
        return;
      } catch (error) {
        await store.dispatch('FED_LOGOUT');
        next({ path: '/' });
        return;
      }
    }

    if (store.getters.needChangePwd) {
      if (to.path === '/need-change-password') {
        next();
      } else {
        next({ path: '/need-change-password', replace: true });
      }
      return;
    }

    if (to.path === '/need-change-password') {
      next({ path: '/' });
    }

    next();
    return;
  }

  if (whiteList.includes(to.name)) {
    next();
    return;
  }
  next('/');
});
