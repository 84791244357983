import Layout from '@/layout/homepage-layout/index.vue';

const routes = [
  {
    path: '/login',
    name: 'Login',
    redirect: '/login/register',
    component: Layout,
    children: [
      {
        path: '/login/main/:type',
        name: 'LoginMain',
        props: true,
        meta: {
          menu: {
            name: '登录',
          },
          activeMenu: '/homepage',
        },
        component: () => import('@/views/home/login/login/index.vue'),
      },
      {
        path: '/login-safe/main/:type',
        name: 'LoginSafeMain',
        props: true,
        meta: {
          menu: {
            name: '登录',
          },
          activeMenu: '/homepage',
        },
        component: () => import('@/views/home/login/login-safe/index.vue'),
      },
      {
        path: '/login/forget-password',
        name: 'ForgetPassword',
        meta: {
          menu: {
            name: '忘记密码',
          },
          activeMenu: '/homepage',
        },
        component: () => import('@/views/home/login/forget-password/index.vue'),
      },
      {
        path: '/login/register',
        name: 'Register',
        meta: {
          menu: {
            name: '注册账号',
          },
          activeMenu: '/homepage',
        },
        component: () => import('@/views/home/login/register/index.vue'),
      },
      {
        path: '/login/bind-phone',
        name: 'BindPhone',
        meta: {
          menu: {
            name: '绑定手机',
          },
          activeMenu: '/homepage',
        },
        component: () => import('@/views/home/login/bind-phone/index.vue'),
      },
      {
        path: '/login/certification',
        name: 'Certification',
        meta: {
          menu: {
            name: '实名认证',
          },
          activeMenu: '/homepage',
        },
        component: () => import('@/views/real-name-certification/index.vue'),
      },
    ],
  },
];

export default routes;
