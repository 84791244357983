import Layout from '@/layout/elderly-layout/index.vue';
import pensionKnowledgeActive from '@/assets/svg/elderly-menu-icon/pension-knowledge-active.svg';
import pensionKnowledgeInActive from '@/assets/svg/elderly-menu-icon/pension-knowledge-inactive.svg';

const routes = [
  {
    path: '/pension-knowledge',
    name: 'PensionKnowledge',
    redirect: '/pension-knowledge/health-consultation',
    component: Layout,
    meta: {
      menu: {
        name: '养老知识',
        icon: {
          active: pensionKnowledgeActive,
          inactive: pensionKnowledgeInActive,
        },
      },
    },
    children: [
      {
        path: '/pension-knowledge/health-consultation',
        name: 'HealthConsultation',
        meta: {
          menu: {
            name: '健康资讯',
          },
          breadcrumb: {
            label: '健康资讯',
          },
        },
        component: () => import('@/views/elderly/pension-knowledge/health-consultation/index.vue'),
      },
      {
        path: '/pension-knowledge/health-consultation/Detail/:id',
        name: 'HealthConsultationDetail',
        props: true,
        meta: {
          menu: {
            name: '资讯详情',
            hidden: true,
          },
          hidden: true,
          breadcrumb: {
            label: '资讯详情',
            parent: 'HealthConsultation',
          },
          activeMenu: '/pension-knowledge/health-consultation',
        },
        component: () => import('@/views/elderly/pension-knowledge/health-consultation/detail/index.vue'),
      },
      {
        path: '/pension-knowledge/chronic-disease-reference',
        name: 'ChronicDiseaseReference',
        meta: {
          menu: {
            name: '慢性病小常识',
          },
          breadcrumb: {
            label: '慢性病小常识',
          },
        },
        component: () => import('@/views/elderly/pension-knowledge/chronic-disease-reference/index.vue'),
      },
      {
        path: '/pension-knowledge/chronic-disease-reference/treatment-plan/:planId',
        name: 'TreatmentPlan',
        props: true,
        meta: {
          menu: {
            name: '治疗方案',
            hidden: true,
          },
          hidden: true,
          breadcrumb: {
            label: '治疗方案',
            parent: 'ChronicDiseaseReference',
          },
          activeMenu: '/pension-knowledge/chronic-disease-reference',
        },
        component: () => import('@/views/elderly/pension-knowledge/chronic-disease-reference/detail/index.vue'),
      },
    ],
  },
];

export default routes;
