import Layout from '@/layout/talent-layout/index.vue';

const routes = [
  {
    path: '/inst-admin/#/',
    name: 'MyOrganization',
    meta: {
      menu: {
        name: '我的机构',
      },
      isExternalLink: true,
    },
    component: Layout,
    children: [],
  },
];

export default routes;
