import Layout from '@/layout/talent-layout/index.vue';

const routes = [
  {
    path: '/subsidy-application',
    name: 'SubsidyApplication',
    redirect: '/subsidy-application/list',
    meta: {
      menu: {
        name: '补贴申请',
      },
    },
    component: Layout,
    children: [
      {
        path: '/subsidy-application/list',
        name: 'SubsidyApplicationList',
        meta: {
          menu: {
            name: '补贴申请',
          },
        },
        component: () => import('@/views/talent/subsidy-application/list.vue'),
      },
      {
        path: '/subsidy-application/submit/:subsidyId',
        name: 'SubsidyApplicationSubmit',
        props: true,
        meta: {
          hidden: true,
          menu: {
            name: '提交申请',
          },
          activeMenu: '/subsidy-application/list',
        },
        component: () => import('@/views/talent/subsidy-application/submit.vue'),
      },
    ],
  },
];

export default routes;
