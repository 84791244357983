<template>
  <div class="app-wrapper">
    <Navbar />
    <AppMain />
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/footer/index.vue';
import AppMain from './app-main.vue';
import Navbar from './navbar/index.vue';

export default {
  components: {
    Navbar,
    AppMain,
    Footer,
  },
  data() {
    return {

    };
  },
  computed: {

  },
};
</script>
