<template>
  <div class="footer">
    <div class="website-info">
      <div class="website-info-detail">
        <div class="detail-item">
          <div class="title">主办单位</div>
          <div class="content">中共北京市通州区委社会工委区民政局</div>
        </div>
        <div class="detail-item">
          <div class="title">承办单位</div>
          <div class="content">北京安和福祉科技有限公司</div>
        </div>
        <div class="detail-item">
          <div class="title">联系电话</div>
          <div class="content">+8613261611111</div>
        </div>
      </div>

      <div class="website-info-detail">
        <div class="detail-item">
          <div class="title">政府网站识别码</div>
          <div class="content">ABCDEFGHIJKL</div>
        </div>
        <div class="detail-item">
          <div class="title">京ICP备案号</div>
          <div class="content">京ICP备20015255号</div>
        </div>
        <div class="detail-item">
          <div class="title">京公网安备</div>
          <div class="content">11011233002-21001</div>
        </div>
      </div>
    </div>

    <div class="copyright">COPYRIGHT © 中共北京市通州区委社会工委区民政局 2021-2023</div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.footer {
  min-width: 1200px;
  .website-info {
    height: 240px;
    width: 1200px;
    min-width: 1200px;
    margin: auto;
    padding: 44px 0 49px;
    box-sizing: border-box;
    background: rgb(255, 255, 255, 80%);
    .website-info-detail {
      display: flex;
      align-items: center;
      padding: 0 0 21px;
      border-bottom: 1px solid #eeeeee;
      .detail-item {
        flex: 1;
        padding-left: 30px;
        border-left: 1px solid #eeeeee;
        line-height: 30px;
        .title {
          color: #999999;
        }
        .content {
          color: #333333;
        }
      }
      .detail-item:first-child {
        flex: 1;
        border-left: none;
      }
    }
    .website-info-detail:last-child {
      padding: 21px 0 0;
      border-bottom: none;
    }
  }
  .copyright {
    text-align: center;
    height: 60px;
    color: #fff;
    line-height: 16px;
    padding: 20px 0 19px;
    box-sizing: border-box;
    background: rgb(250, 79, 116, 60%);
  }
}
</style>
