<template>
  <div class="personal-tabs">
    <router-link
      class="personal-tabs-item"
      v-for="(item, index) in tabList"
      :key="index"
      :to="{ name: item.name}"
    >
      <img
        class="item-icon"
        :src="currentRoute === item.name ? item.acitveIcon : item.inAcitveIcon"
        alt=""
      >
      <span
        class="item-title"
        :class="currentRoute === item.name ? 'curret-tabs' : ''"
      >
        {{ item.title }}
      </span>
    </router-link>
  </div>
</template>

<script>
import accountInfoActiveIcon from '@/assets/svg/account-info-active-icon.svg';
import accountInfoInActiveIcon from '@/assets/svg/account-info-inactive-icon.svg';
import myResumeActiveIcon from '@/assets/svg/my-resume-active-icon.svg';
import myResumeInActiveIcon from '@/assets/svg/my-resume-inactive-icon.svg';
import applicationRecordActiveIcon from '@/assets/svg/application-record-active-icon.svg';
import applicationRecordInActiveIcon from '@/assets/svg/application-record-inactive-icon.svg';
import subsidyApplicationRecordactiveIcon from '@/assets/svg/subsidy-application-record-active.svg';
import subsidyApplicationRecordInactiveIcon from '@/assets/svg/subsidy-application-record-inactive.svg';

export default {
  data() {
    return {
      tabList: [
        {
          name: 'TalentPersonalCenter',
          acitveIcon: accountInfoActiveIcon,
          inAcitveIcon: accountInfoInActiveIcon,
          title: '账号信息',
        },
        {
          name: 'MyResume',
          acitveIcon: myResumeActiveIcon,
          inAcitveIcon: myResumeInActiveIcon,
          title: '我的简历',
        },
        {
          name: 'ApplicationRecord',
          acitveIcon: applicationRecordActiveIcon,
          inAcitveIcon: applicationRecordInActiveIcon,
          title: '应聘记录',
        },
        {
          name: 'SubsidyApplicationRecord',
          acitveIcon: subsidyApplicationRecordactiveIcon,
          inAcitveIcon: subsidyApplicationRecordInactiveIcon,
          title: '补贴申请记录',
        },
      ],
    };
  },

  computed: {
    currentRoute() {
      if (this.$route.name === 'SubsidyApplicationRecordDetail') {
        return 'SubsidyApplicationRecord';
      }
      return this.$route.name;
    },
  },

};
</script>

<style lang="scss" scoped>
.personal-tabs {
  width: 1200px;
  min-width: 1200px;
  margin: 30px auto;
  padding: 0 360px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  .personal-tabs-item {
    display: flex;
    align-items: center;
    .item-icon {
      margin-right: 13px;
    }
    .item-title {
      font-size: 14px;
      line-height: 22px;
      color: #666666;
    }
  }
  .curret-tabs {
    color: #fa4f74 !important;
    position: relative;
    &::after {
      content: '';
      display: inline-block;
      width: 100%;
      height: 4px;
      border-radius: 2px;
      position: absolute;
      left: 0;
      bottom: -6px;
      background: #fa4f74;
    }
  }
}
</style>
