import Layout from '@/layout/talent-layout/index.vue';

const routes = [
  {
    path: '/recruit',
    name: 'Recruit',
    redirect: '/recruit/home',
    meta: {
      menu: {
        name: '投递简历',
      },
    },
    component: Layout,
    children: [
      {
        path: '/recruit/home',
        name: 'recruitHome',
        meta: {
          menu: {
            name: '投递简历',
          },
        },
        component: () => import('@/views/talent/recruit/index.vue'),
      },
      {
        path: '/recruit/application-entry',
        name: 'ApplicationEntry',
        meta: {
          menu: {
            name: '应聘入口',
            hidden: true,
          },
          hidden: true,
          activeMenu: '/recruit/home',
        },
        component: () => import('@/views/talent/sent-resumes/index.vue'),
      },
      {
        path: '/recruit/application-entry/details/:id',
        name: 'PositionDetails',
        props: true,
        meta: {
          menu: {
            name: '职位详情',
            hidden: true,
          },
          hidden: true,
          activeMenu: '/recruit/home',
        },
        component: () => import('@/views/talent/sent-resumes/detail/index.vue'),
      },
      {
        path: '/recruit/talent-personal-center',
        name: 'TalentPersonalCenter',
        meta: {
          menu: {
            name: '账号信息',
            hidden: true,
          },
          hidden: true,
          activeMenu: '/recruit/home',
        },
        component: () => import('@/views/talent/talent-personal-info/account-info/index.vue'),
      },
      {
        path: '/recruit/my-resume',
        name: 'MyResume',
        meta: {
          menu: {
            name: '我的简历',
            hidden: true,
          },
          hidden: true,
          activeMenu: '/recruit/home',
        },
        component: () => import('@/views/talent/talent-personal-info/my-resume/index.vue'),
      },
      {
        path: '/recruit/application-record',
        name: 'ApplicationRecord',
        meta: {
          menu: {
            name: '应聘记录',
            hidden: true,
          },
          hidden: true,
          activeMenu: '/recruit/home',
        },
        component: () => import('@/views/talent/talent-personal-info/application-record/index.vue'),
      },
      {
        path: '/recruit/application-record/organization-detail/:id',
        name: 'ApplicationRecordDetail',
        props: true,
        meta: {
          hidden: true,
          menu: {
            name: '机构详情',
            hidden: true,
          },
          activeMenu: '/recruit/home',
        },
        component: () => import('@/views/elderly/nursing-facility/detail/index.vue'),
      },
      {
        path: '/recruit/subsidy-application-record',
        name: 'SubsidyApplicationRecord',
        meta: {
          menu: {
            name: '补贴申请记录',
            hidden: true,
          },
          hidden: true,
          activeMenu: '/recruit/home',
        },
        component: () => import('@/views/talent/talent-personal-info/subsidy-application-record/list.vue'),
      },
      {
        path: '/recruit/subsidy-application-record/detail/:recordId',
        name: 'SubsidyApplicationRecordDetail',
        props: true,
        meta: {
          menu: {
            name: '详情',
            hidden: true,
          },
          hidden: true,
          activeMenu: '/recruit/home',
        },
        component: () => import('@/views/talent/talent-personal-info/subsidy-application-record/detail.vue'),
      },
    ],
  },
];

export default routes;
