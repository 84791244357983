import Recruit from './modules/talent/recruit';
import TrainingSystem from './modules/talent/training-system';
import MyOrganization from './modules/talent/my-organization';
import SubsidyApplication from './modules/talent/application';

const routes = [
  ...Recruit,
  ...TrainingSystem,
  ...MyOrganization,
  ...SubsidyApplication,
];

export default routes;
