<template>
  <div class="homepage-slidebar">
    <el-menu
      :default-active="activeMenu"
      :background-color="'#fff'"
      :text-color="'#999999'"
      :unique-opened="false"
      :active-text-color="'#fff'"
      :collapse-transition="false"
      mode="horizontal"
      menu-trigger="hover"
    >
      <div class="is-flex full-width">
        <sidebar-item
          v-for="route in routerList"
          :key="route.path"
          :item="route"
          :base-path="route.path"
          :activeMenu="activeMenu"
        />
      </div>
    </el-menu>
  </div>
</template>

<script>
import HomepageRoutes from '@/router/homepageRoutes';
import variables from '@/styles/variables-for-js';
import SidebarItem from './sidebar-item.vue';

export default {
  components: {
    SidebarItem,
  },
  computed: {
    routerList() {
      return HomepageRoutes;
    },
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    variables() {
      return variables;
    },
  },
};
</script>
