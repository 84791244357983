import {
  Login, getUserInfo, Logout, LoginBySafe,
} from '@/api/account';
import { removeToken } from '@/utils/local-store-token';
import dayjs from 'dayjs';
import { sm2 } from 'sm-crypto';

// 登录
export async function LOGIN({ commit }, userInfo) {
  const account = userInfo.account.trim();
  const { password, code } = userInfo;

  const { headers } = await Login({ account, password, code });
  const { 'x-auth-token': token } = headers;

  commit('SET_TOEKN', token);
}

// 登录
export async function LOGIN_SAFE({ commit }, userInfo) {
  const account = userInfo.account.trim();
  const { password, code, publicKey } = userInfo;
  const loginJSON = JSON.stringify({ account, password, code });

  const encryptData = sm2.doEncrypt(loginJSON, publicKey, 1);

  const { headers } = await LoginBySafe({ data: `04${encryptData}` });

  const { 'x-auth-token': token } = headers;

  commit('SET_TOEKN', token);
}

// 获取用户信息
export async function GET_INFO({ commit }) {
  const { data } = await getUserInfo();
  const {
    account,
    wechatNickName,
    realName,
    needPerfection,
    name,
    resume,
    resumeFile,
    nextChangePwdTime,
  } = data.data;
  commit('SET_ACCOUNT', account);
  commit('SET_USERNAME', name);
  commit('SET_WECHAT_NICK_NAME', wechatNickName);
  commit('SET_NEED_PERFECTION', needPerfection);
  commit('SET_REAL_NAME', realName);
  commit('SET_HAS_RESUME', resume);
  commit('SET_HAS_RESUME_FILE', resumeFile);
  if (nextChangePwdTime) {
    const needChangePwd = dayjs().isAfter(nextChangePwdTime);
    commit('SET_NEED_CHANGE_PWD', needChangePwd);
  } else {
    commit('SET_NEED_CHANGE_PWD', false);
  }
}

// 登出
export async function LOGOUT({ commit }) {
  await Logout();
  commit('SET_TOEKN', '');
  commit('SET_ROLES', []);
  removeToken();
  window.location.reload();
}

// 前端登出
export function FED_LOGOUT({ commit }) {
  commit('SET_TOEKN', '');
  removeToken();
  window.location.reload();
}
