<template>
  <div>
    <Header />
    <div
      :class="classObj"
      class="app-wrapper"
    >
      <div
        v-if="device==='mobile' && sidebar.opened"
        class="drawer-bg"
        @click="handleClickOutside"
      />
      <sidebar class="sidebar-container elderly-layout" />
      <div
        class="main-container"
        :class="realName ? '' : 'is-no-real-name'"
      >
        <div :class="{'fixed-header':fixedHeader}">
          <navbar />
        </div>
        <app-main style="overflow: hidden" />
      </div>
    </div>
    <!-- <div
      v-if="!realName || needPerfection"
      class="no-real-name"
    >
      <div class="tips">
        <i class="el-icon-warning tips-icon"></i>
        为了完整使用系统功能，请先
        <el-button
          type="text"
          @click="onRealNameClick"
        >实名认证</el-button>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  Navbar, Sidebar, AppMain, Header,
} from './components';
import ResizeMixin from './mixin/resize-handler';

export default {
  name: 'Layout',
  components: {
    Navbar,
    Sidebar,
    AppMain,
    Header,
  },
  mixins: [ResizeMixin],
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    device() {
      return this.$store.state.app.device;
    },
    fixedHeader() {
      return this.$store.state.settings.fixedHeader;
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile',
      };
    },
    ...mapGetters(['realName', 'needPerfection']),
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false });
    },

    onRealNameClick() {
      this.$router.push({
        name: 'Certification',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/mixin.scss';
@import '~@/styles/variables.scss';

.elderly-layout {
  top: 50px !important;
}

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
  top: 50px;
  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}
.is-no-real-name {
  overflow: hidden;
  max-height: calc(100vh - 50px);
}
.no-real-name {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgb(187, 187, 187, 60%);
  display: flex;
  align-items: center;
  .tips {
    font-size: 14px;
    width: 418px;
    line-height: 80px;
    background: #fff;
    margin: auto;
    text-align: center;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    .tips-icon {
      font-size: 26px;
      color: #ff9800;
      margin-right: 10px;
    }
  }
}
.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 995;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}
</style>
