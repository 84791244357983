import Layout from '@/layout/elderly-layout/index.vue';
import healthManagementActive from '@/assets/svg/elderly-menu-icon/health-management-active.svg';
import healthManagementInActive from '@/assets/svg/elderly-menu-icon/health-management-inactive.svg';

const routes = [
  {
    path: '/health-management',
    name: 'HealthManagement',
    redirect: '/health-management/list',
    component: Layout,
    meta: {
      menu: {
        name: '健康管理',
        icon: {
          active: healthManagementActive,
          inactive: healthManagementInActive,
        },
      },
    },
    children: [
      {
        path: '/health-management/list',
        name: 'HealthManagementList',
        meta: {
          menu: {
            name: '健康管理',
          },
          breadcrumb: {
            label: '选择用户',
          },
        },
        component: () => import('@/views/elderly/health-management/index.vue'),
      },
      {
        path: '/health-management/detail/:id',
        name: 'HealthManagementDetail',
        props: true,
        meta: {
          menu: {
            name: '用户健康管理',
            hidden: true,
          },
          hidden: true,
          breadcrumb: {
            label: '用户健康管理',
            parent: 'HealthManagementList',
          },
          activeMenu: '/health-management/list',
        },
        component: () => import('@/views/elderly/health-management/detail/index.vue'),
      },
      {
        path: '/health-management/detail/:id/report-detail/:reportId',
        name: 'ReportDetail',
        props: true,
        meta: {
          menu: {
            name: '报告详情',
            hidden: true,
          },
          hidden: true,
          breadcrumb: {
            label: '报告详情',
            parent: 'HealthManagementDetail',
          },
          activeMenu: '/health-management/list',
        },
        component: () => import('@/views/elderly/health-management/detail/components/physical-exam-records/detail/index.vue'),
      },
      {
        path: '/health-management/detail/:id/record-detail/:recordId',
        name: 'RecordDetail',
        props: true,
        meta: {
          menu: {
            name: '用药记录',
            hidden: true,
          },
          hidden: true,
          breadcrumb: {
            label: '用药记录',
            parent: 'HealthManagementDetail',
          },
          activeMenu: '/health-management/list',
        },
        component: () => import('@/views/elderly/health-management/detail/components/medication-records/detail/index.vue'),
      },
      {
        path: '/health-management/detail/:id/treatment-plan/:planId',
        name: 'ElderTreatmentPlan',
        props: true,
        meta: {
          menu: {
            name: '治疗方案',
            hidden: true,
          },
          hidden: true,
          breadcrumb: {
            label: '治疗方案',
            parent: 'HealthManagementDetail',
          },
          activeMenu: '/health-management/list',
        },
        component: () => import('@/views/elderly/pension-knowledge/chronic-disease-reference/detail/index.vue'),
      },
    ],
  },
];

export default routes;
