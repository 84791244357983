import Layout from '@/layout/elderly-layout/index.vue';
import nursingFacilityActive from '@/assets/svg/elderly-menu-icon/nursing-facility-active.svg';
import nursingFacilityInActive from '@/assets/svg/elderly-menu-icon/nursing-facility-inactive.svg';

const routes = [
  {
    path: '/nursing-facility',
    name: 'NursingFacility',
    redirect: '/nursing-facilitys/list',
    component: Layout,
    meta: {
      menu: {
        name: '机构列表',
        icon: {
          active: nursingFacilityActive,
          inactive: nursingFacilityInActive,
        },
      },
    },
    children: [
      {
        path: '/nursing-facilitys/list',
        name: 'NursingFacilitysList',
        meta: {
          menu: {
            name: '机构列表',
          },
          breadcrumb: {
            label: '机构列表',
          },
        },
        component: () => import('@/views/elderly/nursing-facility/index.vue'),
      },
      {
        path: '/nursing-facilitys/detail/:id',
        name: 'NursingFacilitysDetail',
        props: true,
        meta: {
          hidden: true,
          menu: {
            name: '机构详情',
            hidden: true,
          },
          breadcrumb: {
            label: '机构详情',
            parent: 'NursingFacilitysList',
          },
          activeMenu: '/nursing-facilitys/list',
        },
        component: () => import('@/views/elderly/nursing-facility/detail/index.vue'),
      },
    ],
  },
];

export default routes;
